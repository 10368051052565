<template>
<div>
  <HeaderView :page_index="-1"></HeaderView>
  <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; vertical-align: top;" />
  <div v-else class="home"  style="text-align: left;">
    <div class="info_view">
      <div class="" style="vertical-align: top;" >
        <div class="linex name_word">{{detail_info.word}}</div>
        <div class="linex circlex">
          <div class="progress-wrapper">
            <el-progress type="circle" :indeterminate="true"  :show-text="false" color="#50b7c1" :stroke-width="8" :percentage="100 * (detail_info.year_long / 300 <= 1 ? detail_info.year_long / 300 : 1) "   />
            <div class="circle_text"><span> {{detail_info.year_long }}</span>年</div>
          </div>
        </div>
        <!-- <div class="linex">
          <span>{{detail_info.year_start}}</span>
          <span style="color: #aaa;">~</span>
          <span>{{detail_info.year_end}}年</span>
        </div> -->
      </div>
      <div v-if="detail_info.key_list">
        <div  v-for="(info , index) in detail_info.key_list"  :key="index" >
          <div v-if="info.list.length">
            <div v-if="info.tag === 'short'" class="short">
              <OneKeyValue  :keyValue="keyValue" tag="short" v-for="(keyValue, index) in info.list"  :key="index" />
            </div>
            <div v-else class="long">
              <OneKeyValue  :keyValue="keyValue" tag="long" v-for="(keyValue, index) in info.list"  :key="index" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <el-tag class="ml-2" type="info" size="large" style="margin-right: 10px; cursor:pointer;" :data-clipboard-text="detail_info.word" @click="go_to('Person_index')">{{detail_info.word}}人物 · {{ detail_info.num_info.person }}</el-tag>
        <el-tag class="ml-2" type="info" size="large" style="margin-right: 10px; cursor:pointer;"  :data-clipboard-text="detail_info.word" @click="go_to('Rank_index')">{{detail_info.word}}榜单 · {{ detail_info.num_info.rank }}</el-tag>
      </div>
    </div>

    <div class="leaders">
      <div class="biaoti">
        <span>尊号</span>
        <span>自主度</span>
        <span>上台</span>
        <span>下台</span>
        <span>死因</span>
      </div>
      <OneLeader :leader_info="leader" tag="short" v-for="(leader, index) in detail_info.leader_list"  :key="index" />
    </div>
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OneKeyValue from '@/components/items/OneKeyValue'
// import router from '@/router/index.js'
import HeaderView from '@/components/other/HeaderView'
import OneLeader from '@/components/items/OneLeader'

export default {
  name: 'Power_detail',
  mounted () {
    this.get_index()
  },
  components: {
    HeaderView, OneLeader, OneKeyValue
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      list: [],
      in_where: null,
      not_in_where: null,
      detail_info: {},
      another_id: null,
      show: {},
      little_info: {}
    }
  },
  methods: {
    get_index () {
      axios.post(Utils.check_is_niming() ? '/power_detail_unlogin/' : '/power_detail/', { id: this.$route.params.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.loading = false
          this.detail_info = res.data.data.info
          document.title = this.detail_info.word
        })
    },
    go_to (pageName) {
      let routeData = null
      if (pageName === 'Power_detail') {
        routeData = this.$router.resolve({ name: pageName, params: { id: this.detail_info.id } })
      } else {
        routeData = this.$router.resolve({ name: pageName, query: { power: this.detail_info.id } })
      }
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 30px 40px 30px 20px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}
.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_top{
  border-top:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_right{
  border-right:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_left{
  border-left:  1px solid rgba(0, 0, 0, 0.08);
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor:pointer; */
}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng2{
  width: 200px;
}
/* li div span.power_select{
  color:#ffd04b;
  font-size: 20px;
} */
#powers{
  font-size: 14px;
}
#powers  span.power_select{
  color:#50b7c1;
}
#powers  .power_select{
  color:#50b7c1;
}
/* #powers  span.name{
  color:#ffd04b;
  font-size: 20px;
} */
li .num{
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  display: inline-block;
  margin-left: 4px;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
.info_head{
  vertical-align: middle;
}
.info_head span,.info_head el-button{
  vertical-align: middle;
}
.info_head span{
  line-height: 20px;
}
.progress-wrapper {
  display: inline-block;
  position: absolute;
  top:-12px;
  left: -12px;
  width: 40px;
  height: 40px;
  transform: scale(0.32);
  transform-origin: center;
}
.circlex{
  position: relative;
  width: 42px;
  height: 42px;
  display: inline-block;
}
.circle_text{
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 125px;
  /* color: #f5f5f5; */
  font-size: 20px;
}
.circle_text span{
  font-size: 36px;
  display: inline-block;
  padding: 0px 4px;
}
.leaders{
  margin: 30px;
}
.info_view{
  margin: 30px;
}
.linex{
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}
.name_word{
  font-size: 40px;
  font-weight: bold;
}
.biaoti{
  padding-left: 530px;
}
.biaoti span{
  display: inline-block;
  width: 60px;
  font-size: 12px;
  color: #ccc;
  text-align: center;
}
</style>

<template>
  <div class="" style="margin-bottom: 6px;">
    <div class="row person" style="text-align: center;" >
      <OnePersonCanPull :person_info="info.person_info" tag="only" />
    </div>
    <!-- {{info.person_info.year_info}} -->
    <div class="sees">
      <div class="line_0" :style="'z-index: 10; left: ' +   + 'px;'">{{info.person_info.year_info.certitude === 'guess' ? '猜' : ''}} {{info.person_info.year_info.start}}年</div>
      <div class="years row" >
        <div class="line_1" style="z-index: 1; background-color:#eee;"></div>
        <div class="line_2" :style="'z-index: 5; background-color:#67C23A; width: '+ (info.person_info.year_info.end - info.person_info.year_info.start === 0 ? 2 : (info.person_info.year_info.end - info.person_info.year_info.start) * 3) + 'px'"></div>
        <div class="line_3" :style="'z-index: 10; background-color: orange; width: '+ ( info.year_end - info.year_start === 0 ? 2 : (info.year_end - info.year_start) * 3 ) + 'px; left: ' +  (info.year_start - info.person_info.year_info.start) * 3  + 'px;'"></div>
        <div class="line_3" v-if="info.year_start2" :style="'z-index: 10; background-color: orange; width: '+ (info.year_end2 - info.year_start2 === 0 ? 2 : (info.year_end2 - info.year_start2) * 3) + 'px; left: ' +  (info.year_start2 - info.person_info.year_info.start) * 3  + 'px;'"></div>
        <div class="line_4" :style="'z-index: 10; left: ' +   (info.person_info.year_info.end - info.person_info.year_info.start === 0 ? 2 : (info.person_info.year_info.end - info.person_info.year_info.start) * 3)   + 'px;'">{{info.person_info.year_info.certitude === 'guess' ? '猜' : ''}} {{info.person_info.year_info.end - info.person_info.year_info.start}}岁</div>
      </div>
      <div class="line_5" :style="'z-index: 10; left: ' +   + 'px;'">{{info.year_start}}~{{info.year_end2 ?info.year_end2 :info.year_end}}</div>
    </div>
    <div class="row info">
      <span>{{ info.zunhao}}</span>
      <span>{{ info.power_level}}</span>

      <span>{{ info.reason_start}}</span>
      <span>{{ info.reason_end}}</span>
      <span>{{ info.reason_death}}</span>
      <span v-if="see_chaodai">{{ info.power_word}}</span>
    </div>
  </div>
</template>
<script>
import store from '@/store'
// import Utils from '@/utils/utils'
// import axios from 'axios'
import OnePersonCanPull from '@/components/items/OnePersonCanPull'

export default {
  name: 'OneLeader',
  props: {
    leader_info: Map,
    show_chaodai: Boolean
  },
  components: {
    OnePersonCanPull
  },
  data () {
    return {
      loading: false,
      info: this.leader_info,
      see_chaodai: this.see_chaodai,
      base_img: store.state.base_img,
      base_url: store.state.base_url,
      show: {}
    }
  },
  methods: {

  }
}
</script>

<style>
.sees {
  display: inline-block;
  /* height: 10px; */
  /* background-color: red; */
}
.sees .line_0{
  width: 40px;
  overflow: hidden;
  display: inline-block;
  /* background-color:#881082; */
  height: 10px;
  font-size: 8px;
  margin-left: 2px;
  color: #bbb;
  text-align: right;
  line-height: 10px;
}
.years{
  position: relative;
  width: 300px;
  height: 10px;
  border-radius: 5px;
}
.line_1{
  width: 300px;
}

.years div{
  position: absolute;
  height: 10px;
  /* border-radius: 5px; */
}
.row{
  display: inline-block;
  margin: 0px 5px;
}
.person{
  width: 100px;
}
.year_start{
  width: 30px;
}
.info span{
  display: inline-block;
  width: 60px;
  font-size: 12px;
  text-align: center;
}
.line_4{
  font-size: 8px;
  line-height: 10px;
  margin-left: 3px;
  color: #bbb;
}
.sees .line_5{
  width: 60px;
  display: inline-block;
  /* background-color:#881082; */
  height: 10px;
  font-size: 8px;
  margin-left: 2px;
  color: #bbb;
  text-align: left;
}
.person,.sees,.info{

  vertical-align: middle;
}
</style>
